import React, { useState, useEffect } from 'react';
import axiosInstance from "../../axiosConfig";
import config from "../../config";
import './EnterPaymentDetails.css';

const EnterPaymentDetails = ({ paymentDetails, onPaymentDetailsChange }) => {
  const [paymentType, setPaymentType] = useState(paymentDetails.paymentType || '');
  const [paymentDate, setPaymentDate] = useState(paymentDetails.paymentDate || new Date().toISOString().split('T')[0]);
  const [checkNumber, setCheckNumber] = useState(null); // State for check number
  const [loadingCheckNumber, setLoadingCheckNumber] = useState(false); // Loading state for check number

  // Propagate initial paymentDate on mount
  useEffect(() => {
    onPaymentDetailsChange({
      ...paymentDetails,
      paymentDate,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Fetch next check number when paymentType is 'Check'
    if (paymentType === 'Check') {
      setLoadingCheckNumber(true);
      axiosInstance
        .get(`${config.API_URL}/payments/next-check-number`)
        .then((response) => {
          setCheckNumber(response.data.nextCheckNumber);
          onPaymentDetailsChange({
            ...paymentDetails,
            paymentNumber: response.data.nextCheckNumber, // Update parent state with check number
          });
        })
        .catch((error) => {
          console.error('Error fetching check number:', error);
          setCheckNumber('Error');
        })
        .finally(() => {
          setLoadingCheckNumber(false);
        });
    } else {
      setCheckNumber(null); // Reset check number if payment type is not 'Check'
      onPaymentDetailsChange({
        ...paymentDetails,
        paymentNumber: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentType]);

  const handlePaymentTypeChange = (e) => {
    const newPaymentType = e.target.value;
    setPaymentType(newPaymentType);
    onPaymentDetailsChange({
      ...paymentDetails,
      paymentType: newPaymentType,
    });
  };

  const handlePaymentDateChange = (e) => {
    const newPaymentDate = e.target.value;
    setPaymentDate(newPaymentDate);
    onPaymentDetailsChange({
      ...paymentDetails,
      paymentDate: newPaymentDate,
    });
  };

  return (
    <div className="enter-payment-details">
      <div>
        <label>
          Payment Type:
          <select value={paymentType} onChange={handlePaymentTypeChange}>
            <option value="">Select Type</option>
            <option value="Check">Check</option>
            <option value="ACH">ACH</option>
            <option value="Wire">Wire</option>
          </select>
        </label>
      </div>
      {paymentType === 'Check' && (
        <div>
          <label>
            Check Number:
            <input
              type="text"
              value={loadingCheckNumber ? 'Loading...' : checkNumber || ''}
              readOnly
            />
          </label>
        </div>
      )}
      <div>
        <label>
          Payment Date:
          <input type="date" value={paymentDate} onChange={handlePaymentDateChange} />
        </label>
      </div>
    </div>
  );
};

export default EnterPaymentDetails;