import React, { useState, useEffect, useMemo, useCallback } from "react";
import axiosInstance from "../axiosConfig";
import PendingActionModal from "./PendingActionModal";
import Modal from "react-modal"; // This is the react-modal import

import config from "../config";
import {
  Table,
  Input,
  Button,
  Form,
  notification,
  Checkbox,
  Col,
  Row,
} from "antd";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import GenericSearch from "./GenericSearch";
import entitySearchConfig from "../entitySearchConfig";
import { getColumns } from "./CustomerDepositColumns";

import "./CustomerDeposit.css";

// Ensure that the modal knows which element it should be attached to
Modal.setAppElement("#root");

const CustomerDeposit = () => {
  const [pendingActions, setPendingActions] = useState([]);
  const [showPendingActionModal, setShowPendingActionModal] = useState(false);
  const [drawerCollapsed, setDrawerCollapsed] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [openInvoices, setOpenInvoices] = useState([]);
  const [checkAmount, setCheckAmount] = useState("");
  const [checkNumber, setCheckNumber] = useState("");
  const [allocations, setAllocations] = useState({});
  const [applyToSuspense, setApplyToSuspense] = useState(false);
  const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false);

  const [form] = Form.useForm();

  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const resetPageState = () => {
    setSelectedCustomer(null);
    setOpenInvoices([]);
    setCheckAmount("");
    setCheckNumber("");
    setAllocations({});
    setApplyToSuspense(false);
    setPendingActions([]);
    setSelectedRow(null);
    setSelectedRowKeys([]);
    form.resetFields();
  };

  const rowSelection = {
    type: "checkbox",
    selectedRowKeys, // The array of selected row keys
    onChange: (selectedKeys, selectedRows) => {
      if (selectedKeys.length > 1) {
        // Only keep the most recent selection, ensuring only one checkbox is checked
        const lastKey = selectedKeys[selectedKeys.length - 1];
        setSelectedRowKeys([lastKey]);
        setSelectedRow(selectedRows[selectedRows.length - 1]); // Store the last selected row data
      } else {
        setSelectedRowKeys(selectedKeys);
        setSelectedRow(selectedRows[0] || null); // Store the selected row data or null if deselected
      }
    },
  };

  const fetchPendingActions = useCallback(async (customerId) => {
    if (!customerId) return; // Avoid unnecessary API call
    try {
      const response = await axiosInstance.get(
        `${config.API_URL}/pending-actions/all?customerId=${customerId}`
      );
      const actions = response.data;
      setPendingActions(actions);

      if (actions.length > 0) {
        setDrawerCollapsed(false); // Auto-expand drawer if there are pending actions
      }
    } catch (error) {
      console.error("Error fetching pending actions:", error);
    }
  }, []);

  useEffect(() => {
    setSelectedRow(null); // Clear the selected row

    if (selectedCustomer && selectedCustomer.id) {
      setOpenInvoices([]); // Clear the current invoices to reset the table
      fetchOpenInvoices(selectedCustomer.id);
      fetchPendingActions(selectedCustomer.id);
    } else {
      setOpenInvoices([]); // Clear state when no customer is selected
      setPendingActions([]); // Clear pending actions as well
    }
  }, [selectedCustomer, fetchPendingActions]);

  const fetchOpenInvoices = async (customerId) => {
    if (!customerId) return; // Avoid unnecessary API call
    try {
      const response = await axiosInstance.get(
        `${config.API_URL}/invoices/customer/${customerId}/open-invoices`
      );
      setOpenInvoices(response.data);
    } catch (error) {
      console.error("Error fetching open invoices:", error);
    }
  };

  const openCustomerModal = () => {
    setIsCustomerModalOpen(true);
  };

  const handleCustomerSelect = (customer) => {
    // console.log("Customer selected:", customer);

    // Check if the customer object has an empty id or name
    const isCustomerCleared = !customer.id || !customer.name;

    if (isCustomerCleared) {
      // console.log("Customer cleared");
      setSelectedCustomer(null); // Explicitly clear the selectedCustomer
      setSelectedRow(null); // Clear selected row when customer is cleared
    } else {
      setSelectedCustomer(customer);
      setOpenInvoices([]);
      setCheckAmount("");
      setCheckNumber("");
      setAllocations({});
      setApplyToSuspense(false);
      setSelectedRow(null); // Clear selected row when a new customer is selected
    }

    form.setFieldsValue({
      customer: customer.name || "",
    });

    setIsCustomerModalOpen(false);
  };

  const onChangeInput = useCallback(
    (e, invoiceId) => {
      const { value } = e.target;

      const invoice = openInvoices.find((inv) => inv.id === Number(invoiceId));

      if (!invoice) {
        notification.error({
          message: "Invoice Not Found",
          description: `Invoice with ID ${invoiceId} was not found.`,
        });
        return;
      }

      if (parseFloat(value) > parseFloat(invoice.balance)) {
        notification.error({
          message: "Allocation Exceeds Invoice Balance",
          description: `You cannot allocate more than the remaining balance of ${invoice.balance}.`,
        });
        return;
      }

      setAllocations((prevAllocations) => ({
        ...prevAllocations,
        [invoiceId]: value,
      }));
    },
    [openInvoices]
  );

  const handleCheckAmountChange = (event) => {
    setCheckAmount(event.target.value);
  };

  const handleCheckNumberChange = (event) => {
    setCheckNumber(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      await form.validateFields();

      const payments = Object.keys(allocations)
        .filter((invoiceId) => allocations[invoiceId] > 0)
        .map((invoiceId) => {
          const invoice = openInvoices.find(
            (inv) => inv.id === Number(invoiceId)
          );

          if (!invoice) {
            console.warn(
              `Invoice with ID ${invoiceId} was not found. Skipping allocation.`
            );
            return null;
          }

          return {
            invoiceId,
            amount: allocations[invoiceId],
            clientId: invoice.clientId,
            closeInvoice: allocations[invoiceId] > 0,
          };
        })
        .filter(Boolean);

      if (payments.length === 0) {
        notification.error({
          message: "No valid allocations",
          description: "Please allocate amounts to at least one invoice.",
        });
        return;
      }

      const totalAllocation = payments.reduce(
        (acc, payment) => acc + parseFloat(payment.amount),
        0
      );

      const remainingAmount = parseFloat(checkAmount) - totalAllocation;

      if (remainingAmount < 0) {
        notification.error({
          message: "Negative Remaining Amount",
          description: "The remaining amount cannot be negative.",
        });
        return;
      }

      const payload = {
        date: new Date(),
        customerId: selectedCustomer.id,
        checkAmount: parseFloat(checkAmount),
        remainingAmount,
        applyToSuspense,
        payments,
      };

      await axiosInstance.post(`${config.API_URL}/deposits/`, payload);

      notification.success({
        message: "Deposit Recorded",
        description: "The deposit has been recorded successfully.",
      });

      resetPageState();
    } catch (error) {
      console.error("Error processing deposit:", error);
      notification.error({
        message: "Error",
        description: "There was an error processing the deposit.",
      });
    }
  };

  const totalAllocation = useMemo(() => {
    return Object.values(allocations)
      .map((value) => parseFloat(value) || 0)
      .reduce((acc, value) => acc + value, 0);
  }, [allocations]);

  const remainingAmount = checkAmount - totalAllocation;

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value);
  };

  const columns = useMemo(
    () => getColumns(openInvoices, allocations, onChangeInput, formatCurrency),
    [openInvoices, allocations, onChangeInput]
  );

  return (
    <div
      className={`customer-deposit-container ${
        drawerCollapsed ? "drawer-collapsed" : ""
      }`}
    >
      <Row gutter={16}>
        <Col
          span={drawerCollapsed ? 24 : 16}
          style={{ height: "calc(100vh - 60px)" }}
        >
          <Form
            form={form}
            style={{ height: "calc(100vh - 60px)" }}
            onFinish={handleSubmit}
            initialValues={{
              checkAmount,
              checkNumber,
              customer: selectedCustomer ? selectedCustomer.name : "",
            }}
          >
            <h1>Customer Deposit</h1>
            <div className="form-header">
              <div className="flex-item full-width">
                <Form.Item
                  name="customer"
                  rules={[{ required: true, message: "Customer is required" }]}
                >
                  <label>
                    Customer:
                    <div className="inline-search-container">
                      <GenericSearch
                        config={entitySearchConfig.customer}
                        onSelectItem={handleCustomerSelect}
                        inlineSearch={true}
                        selectedValue={
                          selectedCustomer ? selectedCustomer.name : ""
                        }
                        autoFocus={true}
                        openModal={openCustomerModal}
                      />
                    </div>
                  </label>
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  name="checkNumber"
                  rules={[
                    { required: true, message: "Check number is required" },
                    {
                      pattern: /^\d+$/,
                      message: "Check number must be numeric",
                    },
                  ]}
                >
                  <label>
                    Check Number:
                    <Input
                      type="text"
                      value={checkNumber}
                      onChange={handleCheckNumberChange}
                    />
                  </label>
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  name="checkAmount"
                  rules={[
                    { required: true, message: "Check amount is required" },
                    {
                      validator: (_, value) =>
                        value && parseFloat(value) > 0
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error("Check amount must be non-zero")
                            ),
                    },
                  ]}
                >
                  <label>
                    Check Amount:
                    <Input
                      type="number"
                      value={checkAmount}
                      onChange={handleCheckAmountChange}
                      onFocus={(e) => e.target.select()}
                      placeholder="0.00"
                    />
                  </label>
                </Form.Item>
              </div>
            </div>

            <Modal
              isOpen={isCustomerModalOpen}
              onRequestClose={() => setIsCustomerModalOpen(false)}
              contentLabel="Customer Search"
              className="search-modal"
              overlayClassName="search-modal-overlay"
            >
              <div className="search-content">
                <div className="search-header">
                  <h2>Customer Search</h2>
                  <button onClick={() => setIsCustomerModalOpen(false)}>
                    &times;
                  </button>
                </div>
                <div className="search-body">
                  <GenericSearch
                    config={entitySearchConfig.customer}
                    onSelectItem={handleCustomerSelect}
                    closeModal={() => setIsCustomerModalOpen(false)}
                    inModal={true}
                  />
                </div>
              </div>
            </Modal>

            <div className="total-amounts">
              <div>
                <strong>
                  Total Allocation: {formatCurrency(totalAllocation)}
                </strong>
              </div>
              <div>
                <strong>
                  Remaining Amount: {formatCurrency(remainingAmount)}
                </strong>
              </div>
            </div>

            <div className="table-container table-container-customer-deposit">
              <Table
                rowSelection={rowSelection}
                columns={columns}
                dataSource={openInvoices}
                rowKey="id"
                pagination={false}
                scroll={{ y: "calc(100vh - 475px)" }}
                sticky
              />
            </div>

            <div className="form-actions">
              <Form.Item>
                <Checkbox
                  checked={applyToSuspense}
                  onChange={(e) => setApplyToSuspense(e.target.checked)}
                  disabled={remainingAmount < 0}
                >
                  Apply Excess To Suspense Account
                </Checkbox>
              </Form.Item>
            </div>

            <div className="fixed-form-buttons">
              <Button
                type="primary"
                htmlType="submit"
                disabled={!applyToSuspense && remainingAmount !== 0}
              >
                Submit Deposit
              </Button>

              <Button
                type="primary"
                onClick={() => {
                  console.log(selectedRow);
                  form.resetFields();
                  form.setFieldsValue({
                    clientName: selectedRow ? selectedRow.client.name : "",
                    invoiceId: selectedRow ? selectedRow.invoiceNumber : "",
                  });
                  setShowPendingActionModal(true);
                }}
                disabled={!selectedCustomer}
              >
                Create Pending Action
              </Button>

              <PendingActionModal
                isVisible={showPendingActionModal}
                onClose={() => setShowPendingActionModal(false)}
                initialValues={{
                  customerName: selectedCustomer?.name || "",
                  invoiceId: selectedRow?.invoiceNumber || "",
                }}
                disableClientFields={!selectedCustomer || !selectedRow}
                onSaveComplete={() => fetchPendingActions(selectedCustomer.id)} // Optional callback to refresh actions
              />
            </div>
          </Form>
        </Col>

        <Col span={drawerCollapsed ? 0 : 8}>
          <div className="drawer-content">
            <h3>Pending Actions</h3>
            {pendingActions.length > 0 ? (
              <Table
                dataSource={pendingActions}
                columns={[
                  { title: "Problem", dataIndex: "problem", key: "problem" },
                  {
                    title: "Disposition",
                    dataIndex: "disposition",
                    key: "disposition",
                  },
                  { title: "Status", dataIndex: "status", key: "status" },
                ]}
                rowKey="id"
                pagination={false}
              />
            ) : (
              <p>No pending actions for this customer.</p>
            )}
          </div>
        </Col>
      </Row>
      <Button
        className={`drawer-toggle ${
          drawerCollapsed ? "drawer-toggle-collapsed" : ""
        } ${pendingActions.length > 0 ? "drawer-toggle-flash" : ""}`}
        onClick={() => setDrawerCollapsed(!drawerCollapsed)}
        icon={drawerCollapsed ? <RightOutlined /> : <LeftOutlined />}
      />
    </div>
  );
};

export default CustomerDeposit;
