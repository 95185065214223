import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import FAQ from './components/FAQ';
import Support from './components/Support';
import GettingStarted from './components/GettingStarted';
import ClientManagementFeature from './components/Features/ClientManagementFeature';
import InvoiceManagementFeature from './components/Features/InvoiceManagementFeature';
import TransactionManagementFeature from './components/Features/TransactionManagementFeature';
import ReportFeature from './components/Features/ReportFeature';
import OrderManagementFeature from './components/Features/OrderManagementFeature';
import CallCenterFeature from './components/Features/CallCenterFeature';
import Login from './components/Login';
import Register from './components/Register';
import ClientList from './components/ClientList';
import AddClient from './components/AddClient';
import ClientDetails from './components/ClientDetails/ClientDetails';
import ClientSearch from './components/ClientSearch';
import AddCustomer from './components/AddCustomer';
import CustomerSearch from './components/CustomerSearch';
import CallCustomer from './components/CallCustomer';
import InvoiceList from './components/InvoiceList';
import AddInvoice from './components/AddInvoice';
import PaymentList from './components/PaymentList';
import AddPayment from './components/AddPayment';
import FundingList from './components/FundingList';
import AddFunding from './components/AddFunding';
import OutstandingInvoices from './components/OutstandingInvoices';
import PaymentsReceived from './components/PaymentsReceived';
import FundingProvided from './components/FundingProvided';
import Profile from './components/Profile';
import Dashboard from './components/Dashboard';
import RoleManagement from './components/RoleManagement';
import Notifications from './components/Notifications';
import Reports from './components/Reports';
import JournalEntriesList from './components/JournalEntriesList';
import AddJournalEntry from './components/AddJournalEntry';
import AccountsPayableList from './components/AccountsPayableList';
import AccountsPayableSearch from './components/AccountsPayableSearch';
import AddAccountsPayable from './components/AddAccountsPayable';
import AccountsPayableDetails from './components/AccountsPayableDetails';
import AccountsReceivableList from './components/AccountsReceivableList';
import AddAccountsReceivable from './components/AddAccountsReceivable';
import AddSchedule from './components/AddSchedule';
import EnterDeposit from './components/EnterDeposit';
import GenerateCollectionReport from './components/GenerateCollectionReport';
import AddOrder from './components/AddOrder';
import ReceiveOrder from './components/ReceiveOrder';
import ApproveOrder from './components/ApproveOrder';
import CustomerDetails from './components/CustomerDetails/CustomerDetails';
import EditPayment from './components/EditPayment';
import Layout from './components/Layout';
import { UserProvider } from './context/UserContext';
import { NotificationProvider } from './context/NotificationContext';
import ProtectedRoute from './components/ProtectedRoute';
import CollectionReportsDashboard from './components/CollectionReportsDashboard';
import ScheduleDetails from './components/ScheduleDetails';
import ScheduleSearch from './components/ScheduleSearch';
import InvoiceDetails from './components/InvoiceDetails';
import CustomerDeposit from './components/CustomerDeposit';
import ClientCreditMemo from './components/ClientCreditMemo';
import CustomerDashboard from './components/CustomerDashboard';
import PaymentPortal from './components/PaymentPortal';
import PaymentHistory from './components/PaymentHistory';
import CreditRequest from './components/CreditRequest';
import ClientDashboard from './components/ClientDashboard';
import ClientInvoices from './components/ClientInvoices';
import ClientFunding from './components/ClientFunding';
import ClientPayments from './components/ClientPayments';
import ClientReports from './components/ClientReports';
import ClientDocuments from './components/ClientDocuments';
import ApplyCreditMemo from './components/ApplyCreditMemo';
import JobManagement from './components/JobManagement';
import LogManagement from './components/LogManagement';
import PurchaseOrderSearch from './components/PurchaseOrderSearch';
import PurchaseOrderDetails from './components/PurchaseOrderDetails';
import FinancialStatement from './components/FinancialStatements';
import BalanceSheetManager from './components/BalanceSheetManager';
import IncomeStatementManager from './components/IncomeStatementManager';
import PaymentWizard from './components/PaymentWizard/PaymentWizard';
import SupplierSearch from './components/SupplierSearch';
import AddSupplier from './components/AddSupplier';
import SupplierDetails from './components/SupplierDetails/SupplierDetails';
import FactoringDashboard from './components/FactoringDashboard';
import PendingActionsDashboard from './components/PendingActionsDashboard';
import AddEmployee from './components/AddEmployee';
import EmployeeDetails from './components/EmployeeDetails';
import EmployeeSearch from './components/EmployeeSearch';
import PayrollInput from './components/PayrollInput';
import IRSReports from './components/IRSReports';

function App() {
  return (
    <Router>
      <UserProvider>
        <NotificationProvider>
          <Layout>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/features/client-management" element={<ClientManagementFeature />} />
              <Route path="/features/invoice-management" element={<InvoiceManagementFeature />} />
              <Route path="/features/transactions" element={<TransactionManagementFeature />} />
              <Route path="/features/reports" element={<ReportFeature />} />
              <Route path="/features/order-management" element={<OrderManagementFeature />} />
              <Route path="/features/call-center" element={<CallCenterFeature />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/support" element={<Support />} />
              <Route path="/getting-started" element={<GettingStarted />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/clients" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><ClientList /></ProtectedRoute>} />
              <Route path="/add-client" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><AddClient /></ProtectedRoute>} />
              <Route path="/add-employee" element={<ProtectedRoute allowedRoles={['admin']}><AddEmployee /></ProtectedRoute>} />
              <Route path="/employees/:id" element={<ProtectedRoute allowedRoles={['admin']}><EmployeeDetails /></ProtectedRoute>} />
              <Route path="/clients/:clientId" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><ClientDetails /></ProtectedRoute>} />
              <Route path="/client-search" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><ClientSearch /></ProtectedRoute>} />
              <Route path="/add-customer" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><AddCustomer /></ProtectedRoute>} />
              <Route path="/customer-search" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><CustomerSearch /></ProtectedRoute>} />
              <Route path="/call-customer" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><CallCustomer /></ProtectedRoute>} />
              <Route path="/invoices" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><InvoiceList /></ProtectedRoute>} />
              <Route path="/add-invoice" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><AddInvoice /></ProtectedRoute>} />
              <Route path="/payments" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><PaymentList /></ProtectedRoute>} />
              <Route path="/add-payment" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><AddPayment /></ProtectedRoute>} />
              <Route path="/edit-payment/:id" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><EditPayment /></ProtectedRoute>} />
              <Route path="/fundings" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><FundingList /></ProtectedRoute>} />
              <Route path="/add-funding" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><AddFunding /></ProtectedRoute>} />
              <Route path="/reports/outstanding-invoices" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><OutstandingInvoices /></ProtectedRoute>} />
              <Route path="/reports/payments-received" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><PaymentsReceived /></ProtectedRoute>} />
              <Route path="/reports/funding-provided" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><FundingProvided /></ProtectedRoute>} />
              <Route path="/profile" element={<ProtectedRoute allowedRoles={['admin', 'employee', 'client', 'customer']}><Profile /></ProtectedRoute>} />
              <Route path="/dashboard" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><Dashboard /></ProtectedRoute>} />
              <Route path="/role-management" element={<ProtectedRoute allowedRoles={['admin']}><RoleManagement /></ProtectedRoute>} />
              <Route path="/notifications" element={<ProtectedRoute allowedRoles={['admin', 'employee', 'client', 'customer', 'user']}><Notifications /></ProtectedRoute>} />
              <Route path="/reports" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><Reports /></ProtectedRoute>} />
              <Route path="/journal-entries" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><JournalEntriesList /></ProtectedRoute>} />
              <Route path="/add-journal-entry" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><AddJournalEntry /></ProtectedRoute>} />
              <Route path="/accounts-payable" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><AccountsPayableList /></ProtectedRoute>} />
              <Route path="/accounts-payable-search" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><AccountsPayableSearch /></ProtectedRoute>} />
              <Route path="/accounts-payable/:accountsPayableId" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><AccountsPayableDetails /></ProtectedRoute>} />
              <Route path="/add-accounts-payable" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><AddAccountsPayable /></ProtectedRoute>} />
              <Route path="/accounts-receivable" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><AccountsReceivableList /></ProtectedRoute>} />
              <Route path="/add-accounts-receivable" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><AddAccountsReceivable /></ProtectedRoute>} />
              <Route path="/add-schedule" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><AddSchedule /></ProtectedRoute>} />
              <Route path="/enter-deposit" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><EnterDeposit /></ProtectedRoute>} />
              <Route path="/generate-collection-report" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><GenerateCollectionReport /></ProtectedRoute>} />
              <Route path="/orders/new" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><AddOrder /></ProtectedRoute>} />
              <Route path="/orders/:orderId" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><ReceiveOrder /></ProtectedRoute>} />
              <Route path="/orders/:orderId/approve" element={<ProtectedRoute allowedRoles={['admin']}><ApproveOrder /></ProtectedRoute>} />
              <Route path="/customers/:customerId" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><CustomerDetails /></ProtectedRoute>} />
              <Route path="/collection-reports-dashboard" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><CollectionReportsDashboard /></ProtectedRoute>} />
              <Route path="/schedules/:scheduleId" element={<ProtectedRoute allowedRoles={['admin', 'employee', 'client']}><ScheduleDetails /></ProtectedRoute>} />
              <Route path="/schedule-search" element={<ProtectedRoute allowedRoles={['admin', 'employee', 'client']}><ScheduleSearch /></ProtectedRoute>} />
              <Route path="/invoices/:invoiceId" element={<ProtectedRoute allowedRoles={['admin', 'employee', 'client']}><InvoiceDetails /></ProtectedRoute>} />
              <Route path="/customer-deposit" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><CustomerDeposit /></ProtectedRoute>} />
              <Route path="/client-credit-memo" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><ClientCreditMemo /></ProtectedRoute>} />
              <Route path="/customer-dashboard" element={<ProtectedRoute allowedRoles={['customer']}><CustomerDashboard /></ProtectedRoute>} />
              <Route path="/payment-portal" element={<ProtectedRoute allowedRoles={['customer']}><PaymentPortal /></ProtectedRoute>} />
              <Route path="/payment-history" element={<ProtectedRoute allowedRoles={['customer']}><PaymentHistory /></ProtectedRoute>} />
              <Route path="/credit-request" element={<ProtectedRoute allowedRoles={['customer']}><CreditRequest /></ProtectedRoute>} />
              <Route path="/client-dashboard" element={<ProtectedRoute allowedRoles={['client']}><ClientDashboard /></ProtectedRoute>} />
              <Route path="/client-invoices" element={<ProtectedRoute allowedRoles={['client']}><ClientInvoices /></ProtectedRoute>} />
              <Route path="/client-funding" element={<ProtectedRoute allowedRoles={['client']}><ClientFunding /></ProtectedRoute>} />
              <Route path="/client-payments" element={<ProtectedRoute allowedRoles={['client']}><ClientPayments /></ProtectedRoute>} />
              <Route path="/client-reports" element={<ProtectedRoute allowedRoles={['client']}><ClientReports /></ProtectedRoute>} />
              <Route path="/client-documents" element={<ProtectedRoute allowedRoles={['client']}><ClientDocuments /></ProtectedRoute>} />
              <Route path="/apply-credit-memo/:invoiceId" element={<ProtectedRoute allowedRoles={['client']}><ApplyCreditMemo /></ProtectedRoute>} />
              <Route path="/job-management" element={<ProtectedRoute allowedRoles={['admin']}><JobManagement /></ProtectedRoute>} />
              <Route path="/log-management" element={<ProtectedRoute allowedRoles={['admin']}><LogManagement /></ProtectedRoute>} />
              <Route path="/purchase-order-search" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><PurchaseOrderSearch /></ProtectedRoute>} />
              <Route path="/purchase-orders/:orderId" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><PurchaseOrderDetails /></ProtectedRoute>} />
              <Route path="/financial-statements" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><FinancialStatement /></ProtectedRoute>} />
              <Route path="/balance-sheet-manager" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><BalanceSheetManager /></ProtectedRoute>} />
              <Route path="/income-statement-manager" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><IncomeStatementManager /></ProtectedRoute>} />
              <Route path="/create-payment" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><PaymentWizard /></ProtectedRoute>} />
              <Route path="/supplier-search" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><SupplierSearch /></ProtectedRoute>} />
              <Route path="/add-supplier" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><AddSupplier /></ProtectedRoute>} />
              <Route path="/suppliers/:supplierId" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><SupplierDetails /></ProtectedRoute>} />
              <Route path="/factoring-dashboard" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><FactoringDashboard /></ProtectedRoute>} />
              <Route path="/pending-actions-dashboard" element={<ProtectedRoute allowedRoles={['admin', 'employee']}><PendingActionsDashboard /></ProtectedRoute>} />
              <Route path="/employee-search" element={<ProtectedRoute allowedRoles={['admin']}><EmployeeSearch /></ProtectedRoute>} />
              <Route path="/payroll-input" element={<ProtectedRoute allowedRoles={['admin']}><PayrollInput /></ProtectedRoute>} />
              <Route path="/irs-reports" element={<ProtectedRoute allowedRoles={['admin']}><IRSReports /></ProtectedRoute>} />
            </Routes>
          </Layout>
        </NotificationProvider>
      </UserProvider>
    </Router>
  );
}

export default App;
