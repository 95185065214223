import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useUserContext } from '../context/UserContext';
import './Navigation.css';

const subMenuStateKey = 'subMenuState';

function Navigation({ isSidebarOpen, isSidebarPinned, closeSidebar }) {
  const { user } = useUserContext();

  const getInitialSubMenuState = () => {
    const savedState = localStorage.getItem(subMenuStateKey);
    return savedState
      ? JSON.parse(savedState)
      : {
          clients: true,
          customers: true,
          invoices: true,
          payments: true,
          fundings: true,
          reports: true,
          accounting: true,
          schedules: true,
          advances: true,
          deposits: true,
          collections: true,
          orders: true,
        };
  };

  const [openSections, setOpenSections] = useState(getInitialSubMenuState);

  useEffect(() => {
    localStorage.setItem(subMenuStateKey, JSON.stringify(openSections));
  }, [openSections]);

  const toggleSection = (section) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const handleLinkClick = () => {
    closeSidebar(); // Close sidebar when a link is clicked
  };

  const renderLink = (path, label, key, exact = false) => (
    <li key={key}>
      <NavLink
        to={path}
        className={({ isActive }) => (isActive ? 'active' : '')}
        end={exact}
        onClick={handleLinkClick} // Close sidebar when clicked
      >
        {label}
      </NavLink>
    </li>
  );

  const renderSection = (section, label, links) => (
    <li key={section}>
      <div onClick={() => toggleSection(section)} className={openSections[section] ? 'active' : ''}>
        {label}
      </div>
      <ul className={openSections[section] ? 'expanded' : ''}>
        {links.map((link, index) => React.cloneElement(link, { key: index }))}
      </ul>
    </li>
  );

  return (
    <div className={`sidebar-container ${isSidebarPinned ? `pinned` : isSidebarOpen ? 'open' : 'collapsed'}`}>
      <nav className="sidebar">
        <ul>
          {renderLink("/", "Home", "home-link")}
          {user && (user.role === 'admin' || user.role === 'employee') && renderLink("/factoring-dashboard", "Factoring Dashboard", "factoring-dashboard-link")}
          {user && (user.role === 'admin' || user.role === 'employee') && renderLink("/pending-actions-dashboard", "Pending Actions Dashboard", "pending-actions-dashboard-link")}

          {user && (user.role === 'admin') && renderSection('admin', 'Admin', [
            renderLink("/role-management", "Role Management", "role-management"),
            renderLink("/job-management", "Job Management", "job-management-link"),
            renderLink("/log-management", "Log Management", "log-management-link"),
            renderLink("/add-employee", "Add Employee", "add-employee-link"),
            renderLink("/employee-search", "Employee Search", "employee-search-link"),
            renderLink("/payroll-input", "Payroll Input", "payroll-input-link"),
          ])}

          {user && (user.role === 'admin' || user.role === 'employee') && renderSection('clients', 'Clients', [
            renderLink("/add-client", "Add Client", "add-client-link"),
            renderLink("/client-search", "Client Search", "client-search-link"),
            <hr key="clients-hr-1" />,
            renderLink("/orders/new", "Add Order", "receive-order-link"),
            renderLink("/purchase-order-search", "Purchase Order Search", "purchase-order-search-link"),
            <hr key="clients-hr-2" />,
            renderLink("/add-schedule", "Add Schedule", "add-schedule-link"),
            renderLink("/schedule-search", "Schedule Search", "schedule-search-link"),
            <hr key="clients-hr-3" />,
            renderLink("/generate-collection-report", "Collection Report", "collection-report-link"),
            renderLink("/collection-reports-dashboard", "Collection Reports Dashboard", "collection-reports-dashboard-link"),
            <hr key="clients-hr-4" />,
            renderLink("/create-payment", "Create Payment", "create-payment-link"),
          ])}

          {user && (user.role === 'admin' || user.role === 'employee') && renderSection('customers', 'Customers', [
            renderLink("/add-customer", "Add Customer", "add-customer-link"),
            renderLink("/customer-search", "Customer Search", "customer-search-link"),
            <hr key="customers-hr-1" />,
            renderLink("/call-customer", "Call Customer", "call-customer-link"),
            <hr key="customers-hr-2" />,
          ])}

          {user && (user.role === 'admin' || user.role === 'employee') && renderSection('suppliers', 'Suppliers', [
            renderLink("/add-supplier", "Add Supplier", "add-supplier-link"),
            renderLink("/supplier-search", "Supplier Search", "supplier-search-link"),
            renderLink("/add-supplier-invoice", "Add Supplier Invoice", "add-supplier-invoice-link"),
          ])}

          {user && (user.role === 'admin' || user.role === 'employee') && renderSection('invoices', 'Invoices', [
            renderLink("/client-credit-memo", "Client Credit Memo", "client-credit-memo-link"),
            renderLink("/customer-deposit", "Customer Deposit", "customer-deposit-link"),
          ])}

          {user && (user.role === 'admin' || user.role === 'employee') && renderSection('accounting', 'Accounting', [
            renderLink("/irs-reports", "IRS Reports", "irs-reports-link"),
            renderLink("/accounts-payable-search", "Accounts Payable Search", "accounts-payable-search-link"),
            renderLink("/balance-sheet-manager", "Balance Sheets", "balance-sheets-link"),
            renderLink("/income-statement-manager", "Income Statements", "income-statements-link"),
          ])}

          {user && (user.role === 'client') && renderSection('client-portal', 'Client Portal', [
            renderLink("/client-dashboard", "Dashboard", "client-dashboard-link"),
            renderLink("/client-invoices", "Invoices", "client-invoices-link"),
            renderLink("/client-funding", "Funding", "client-funding-link"),
            renderLink("/client-payments", "Payments", "client-payments-link"),
            renderLink("/client-reports", "Reports", "client-reports-link"),
            renderLink("/client-documents", "Documents", "client-documents-link"),
          ])}

          {user && (user.role === 'customer') && renderSection('customer-portal', 'Customer Portal', [
            renderLink("/customer-dashboard", "Dashboard", "customer-dashboard-link"),
            renderLink("/payment-portal", "Payment Portal", "payment-portal-link"),
            renderLink("/payment-history", "Payment History", "payment-history-link"),
            renderLink("/credit-request", "Credit/RA Request", "credit-ra-request-link"),
          ])}
        </ul>
      </nav>
    </div>
  );
}

export default Navigation;
